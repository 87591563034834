<template>
  <q-table flat wrap-cells
    :data="entities"
    :columns="columns"
    row-key="id"
    :rows-per-page-options="rowsPerPageOptions"
    :pagination.sync="pagination"
    :loading="loading"
    :filter="filter"
    @request="onRequest"
    binary-state-sort>
    <q-tr slot="body" slot-scope="props" :props="props"
      @click.native="$router.push({ name: 'entity-show', params: { id: props.row.id } })"
      class="cursor-pointer">
      <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
        <div v-if="walletFields.indexOf(col.name) >= 0">
          <div v-for="(val, index) in col.value" v-bind:key="`${index}`">
            <div v-if="col.name === 'TYPE_RGT'">
              <div v-if="val === 'PDV'">
                <q-icon name="fiber_manual_record" />
              </div>
              <div v-else>&nbsp;</div>
            </div>
            <div v-else-if="col.name === 'NOMPTF'">
              <div v-if="val" style="white-space: nowrap">
                {{ val }}
              </div>
              <div v-else>&nbsp;</div>
            </div>
            <div v-else-if="col.name === 'mdt_gest'">
              <div v-if="val">
                {{ val }}
              </div>
              <div v-else>&nbsp;</div>
            </div>
            <div v-else>{{ val }}</div>
          </div>
        </div>
        <div v-else-if="col.name === 'agents'">
          <div v-for="agent in col.value" :key="agent.id">
            {{agent['PRENOM']}} {{agent['NOMITV']}}
          </div>
        </div>
        <div v-else>
          <div v-if="col.name === 'segment' && col.value">
            {{ col.value.label }}
          </div>
          <div v-else-if="col.value">{{ col.value }}</div>
        </div>
      </q-td>
    </q-tr>
  </q-table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Entitieslist',

  meta () {
    return {
      title: 'Liste des entités'
    }
  },

  data () {
    return {
      filter: '',
      walletFields: ['NOMPTF', 'TYPE_RGT', 'mdt_gest', 'total_commissions'],
      orderColumns: ['label', 'segment', 'agents', 'NOMPTF', 'TYPE_RGT', 'mdt_gest', 'postes', 'total_commissions'],
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      entities: 'entities/getEntities',
      filters: 'entities/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    pagination: {
      ...mapGetters({ get: 'entities/getPagination' }),
      ...mapMutations({ set: 'entities/setPagination' })
    },

    columns () {
      if (this.entities.length === 0) return []

      const fields = ['label', 'segment', 'agents', 'postes']
      const sortable = ['label', 'is_red']

      let cols = fields.map(key => {
        let label = this.$t(`entities.${key}`)
        let align = 'left'
        let autoWidth = false
        let formatFn = val => val

        if (key === 'agents') {
        } else if (key === 'is_red') {
          align = 'center'
        }

        return {
          name: key,
          align: align,
          label: label,
          field: key,
          format: formatFn,
          sortable: sortable.indexOf(key) >= 0,
          autoWidth: autoWidth
        }
      })

      cols = cols.concat(this.walletFields.map(key => {
        let label = this.$t(`wallets.${key}`)
        let align = 'left'
        let autoWidth = false
        let formatFn = val => val.map(wallet => wallet[key] || '')

        if (key === 'TYPE_RGT') {
          align = 'center'
        } else if (key === 'mdt_gest') {
          align = 'center'
          formatFn = val => val.map(wallet => Number(wallet[key]) > 0 ? Number(wallet[key]) : '')
          autoWidth = true
        } else if (key === 'total_commissions') {
          align = 'center'
          formatFn = val => val.map(wallet => this.comm_totale(wallet))
        } else if (key === 'NOMPTF') {
          label = this.$t(`wallets.nom_du_pdf`)
        }

        return {
          name: key,
          align: align,
          label: label,
          field: 'wallets',
          format: formatFn,
          sortable: sortable.indexOf(key) >= 0,
          autoWidth: autoWidth
        }
      }))

      cols.sort((a, b) => this.orderColumns.findIndex(columnName => columnName === a.name) > this.orderColumns.findIndex(columnName => columnName === b.name))

      return cols
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.$nextTick(() => {
          this.onRequest({ pagination: this.pagination })
        })
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) {
      this.onRequest({ pagination: this.pagination })
    }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('entities/setPagination', pagination)
      this.loading = true

      let params = { ...pagination }
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      this.$store.dispatch('entities/fetchEntities', params)
        .finally(() => { this.loading = false })
    },
    comm_totale (wallet) {
      const comm_tot_ia = Number(wallet['MT_COMM_TOT_IA_N1'])
      const comm_tot_vs = Number(wallet['MT_COMM_TOT_VS_N1'])
      return `${Math.round((comm_tot_ia + comm_tot_vs) / 1000)} K€`
    }
  }
}
</script>
